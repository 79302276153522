// to add to r24
body {
  font-family: "Ubuntu";
}
a:hover {
  text-decoration: none;
}
.r24btn--pri:hover {
  background: #45a5ff;
}
.r24btn--light:hover {
  background: #f3f3f3;
  color: #212529;
  border-color: #ddd;
}
.r24btn--link {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0 5px;
  color: #4285f4;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: transparent;
    color: $grey7;
  }
}
.r24filters {
  // flex-wrap: wrap;
  .r24btn--tiny {
    padding: 3px 8px;
    font-size: 10px;
  }
}
.r24filters--right {
  margin-right: 0;
}
.r24filters__row {
  width: 100%;
  display: flex;
  margin-top: 10px;
}
.r24filters__single:last-child {
  margin-right: 0;
}
.r24filters__single.r24filters__label {
  font-size: 11px;
  color: #666;
}

// overrides
.r24phead .r24filters {
  // margin-top: 70px;
  margin-top: 100px;
  margin-bottom: 30px;
}
.r24filters__single {
  display: block;
}

// app styles
.App {
  background: #f0f2f5;
}
.fc-theme-standard {
  position: relative;
  .fc-header-toolbar {
    position: absolute;
    top: -130px;
    left: 0;
    right: 0;
  }
}
.fc .fc-toolbar-title {
  font-size: 30px;
  line-height: 37px;
  color: $primary-dark;
}
.fc .fc-button {
  padding: 8px 17px;
  background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 99.97%);
  border: 1px solid #dddddd !important;
  box-sizing: border-box;
  border-radius: 3px 0px 0px 3px;
  color: $grey3;
  font-size: 12px;
  text-transform: capitalize;
  // line-height: 14px;
}
.fc-next-button.fc-button.fc-button-primary {
  margin-left: -1px;
}

.fc .fc-button.fc-button-active {
  background: #666666;
  border: 1px solid #dddddd;
  color: #fff;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled):hover,
.fc .fc-button-primary:disabled {
  color: $grey3;
}
.fc .fc-button.fc-button-active:hover {
  color: #fff;
}
.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}
// table styles
.fc .fc-view-harness {
  background: #fff;
}
.fc .fc-datagrid-header .fc-datagrid-cell-frame,
.fc .fc-timeline-slot-frame {
  height: 40px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 11px;
  color: $primary-dark;
  background-color: #f8f8f8;
}
.fc .fc-datagrid-header .fc-datagrid-cell-frame {
  font-size: 10px;
  line-height: 11px;
  color: $grey3;
}
.fc-datagrid-body .fc-datagrid-cell-main {
  font-size: 12px;
  line-height: 14px;
  strong {
    font-weight: normal;
  }
}
.fc-datagrid-header .fc-datagrid-cell-cushion {
  padding: 12px 15px 12px 20px;
}
.fc {
  .r24tech__col {
    border: 0;
    margin-top: 10px;
    padding-left: 10px;
    div {
      flex-grow: 1;
    }
  }

  .r24tech__time {
    margin-left: auto;
  }
}
.fc-datagrid-body .fc-datagrid-cell-cushion {
  padding: 0;
  min-height: 64px !important;
  // overflow: hidden;
}
.fc-timeline-lane-frame {
  // some stuff
}
.fc-timeline-event .fc-event-time,
.fc-timeline-event .fc-event-title {
  padding: 0;
}
.fc .fc-timeline-event-harness {
  padding: 5px;
}
.fc-timeline-event {
  border-radius: 3px;
  padding: 0px !important;
  border-color: transparent !important;
  border-width: 0px !important;
  .fc-event-enable{
    padding: 5px 8px;
  }
  .fc-event-disable{
    background-color: rgba(255,255,255,0.33);
    opacity:0.8;
    padding: 5px 8px;
  }
  .fc-event-inner {
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    .fc-event-pri {
      opacity: 0.7;
      font-size: 11px;
    }
    .fc-event-time {
      font-weight: normal;
      margin-bottom: -2px;
    }
  }
}
.fc-datagrid-cell-cushion {
  .r24btn--link {
    font-size: 12px;
    margin-left: 12px;
    opacity: 0;
    transition: all 0.2s;
  }
  &:hover .r24btn--link {
    opacity: 1;
  }
}
.r24modal__right .r24fg--sep {
  width: 100%;
}
.r24pw__input {
  width: 100%;
}
.r24pw__show {
  font-size: 11px;
  cursor: pointer;
}
.r24fg--check .r24fg__toggler {
  top: 2px;
  img {
    display: block;
  }
}
.r24fg--check .r24fg__inlabel {
  font-size: 12px;
}
.r24modal__right {
  width: 58%;
}
.r24fg--fixed {
  min-width: 128px;
}

.r24tech__desc {
  display: flex;
}

.r24fg__input:disabled {
  background-color: #f4f4f4;
}

.react-timerange-picker__wrapper {
  border: solid 1px #dddddd;
  border-radius: 5px;
  background: white;

  .react-timerange-picker__inputGroup {
    padding: 6px 10px;
  }

  input:focus {
    outline: none;
  }
}

.r24shift__type {
  padding-left: 10px;
}

.r24fg p.errors {
  font-size: 12px;
  margin-top: 5px;
  color: #b20c0c;
  margin-bottom: 0px;
}

.r24fg__input.has-errors {
  border-color: #b20c0c;
}

.popover-header,
.popover-body {
  font-size: 12px;
}

.multi-select .r24__menu {
  z-index: 2;
}

.multi-select {
  .r24__control {
    height: auto;
    min-height: 34px !important;
    font-size: 12px;
    border-color: $grey1;
  }
  .r24__indicators {
    height: 34px;
  }
  .r24__menu {
    font-size: 12px;
  }
  .r24__indicator-separator {
    display: none;
  }
  .r24__clear-indicator {
    padding: 8px 0;
  }
  .r24__dropdown-indicator {
    padding-left: 0;
  }
}
.r24modal__right {
  .r24__control {
    height: auto !important;
    min-height: 34px;
  }
}
.react-timerange-picker__inputGroup {
  min-width: calc((4px) + 0.34em * 6 + 0.217em * 2);
}
.r24filters .r24fg--multi {
  width: auto;
  // width: 100%;
  min-width: 148px;
}
.r24filters .r24__multi-value {
  max-width: 75px;
}
.r24filters .r24fg--time {
  max-width: 260px;
  width: auto;
}
.react-timerange-picker__inputGroup__input,
.react-timerange-picker__inputGroup__leadingZero {
  font-size: 12px;
}
.react-timerange-picker__wrapper .react-timerange-picker__inputGroup {
  padding: 4px 10px;
}

.r24modal__right {
  .r24fg__addlink {
    text-align: left;
    margin-top: 0px;
  }
  .react-timerange-picker {
    margin-bottom: 5px;
  }
}

.r24-error-alerts {
  font-size: 12px;
  ul {
    padding-left: 15px;
    margin-bottom: 0;
  }
}

.delete-shift, .delete-technician {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: red !important;
  font-size: 12px;
  text-decoration: underline !important;
  cursor: pointer;
}

.r24fg__input {
  white-space: normal;
}

.progress-bar {
  background-color: $primary-blue;
}

.r24fg__timerange {
  .timerange-picker {
    position: relative;
    margin-bottom: 5px;
    .delete {
      position: absolute;
      top: 3px;
      right: 0;
      width: 30px;
      height: 30px;
      padding-left: 8px;
    }
  }

  p.errors {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .has-errors .react-timerange-picker__wrapper {
    border-color: #b20c0c;
  }
}

.r24fg__phone {
  width: 100%;

  font-size: 12px;
  height: 34px;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  color: #333333;
  border: 1px solid #ececec;
  white-space: nowrap;
  line-height: 34px;
  text-decoration: none;
  border-radius: 5px;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  background-image: none;
  &:disabled {
    background-color: #f4f4f4;
  }

  &.has-errors {
    border-color: #b20c0c;
  }
}
.r24greybg {
  background-color: #f0f2f5;
}

.r24fg__input.fw {
  width: 100%;
}

.r24tech__number {
  font-size: 11px;
  color: $grey2;
  font-weight: 500;
  padding: 2px 0px 2px 12px;
  display: inline-block;
}

.r24tech__name {
  font-size: 12px;
}

#DateRangePickerContainer {
  .rangecontainer {
    display: none;
  }
}
.export-schedule-button {
  margin-left: 7px;
}
.export-schedule .rdrDefinedRangesWrapper {
  display: none;
}
.export-schedule-action {
  float: right;
  margin-left: 12px;
  margin-bottom: 12px;
}
.export-schedule-modal {
  max-width: 374px;
}
.tenant-notes-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}
.schedule-hours-modal-body {
 width: auto;
 height:420px;
}
.date-pickers-main-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.date-picker-container{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
}
.date-picker{
  width: 100%;
}
.date-time-label {
  text-align: center;
  font-weight: 700;
  padding-bottom: 5px;
  font-size: 13px;
}
.r24tech__activity__header {
background: #F8F8F8;
border: 1px solid #DDDDDD;
box-sizing: border-box;
border-radius: 3px;
padding-left: 2px;
padding-right: 2px;
}
.r24tech__activity__table{
  font-size: 12px;
  line-height: 14px;
}
.shifts_loader{
  position:absolute;
  background-color: transparent;
  z-index:100;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  margin-top: 50px;
  height: 80%;
  width: 100%;
}
.shifts_header_loader{
  position:absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  cursor: not-allowed;
  z-index: 2;
}

.rdw-center-aligned-block * {
  text-align: center !important;
}

.rdw-left-aligned-block * {
  text-align: left !important;
}

.rdw-right-aligned-block * {
  text-align: right !important;
}

.rdw-justify-aligned-block * {
  text-align: justify !important;
}

