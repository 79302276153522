$primary-blue: #4285f4;
$primary-meg: #bf4346;
$primary-dark: #19333f;

$body-text: #666;
$title-text: #19333f;

$grey1: #dddddd;
$grey2: #999999;
$grey3: #333333;
$grey4: #ececec;
$grey5: #f9f9f9;
$grey6: #f1f1f1;
$grey7: #666666;

$error-red: #b4060b;

$primary-blue-lighter: #45a5ff;
$primary-blue-darker: #3d86ff;

@mixin def-gradient {
  background-image: linear-gradient(to bottom, #ffffff, #f3f3f3);
}
@mixin pri-gradient {
  background-image: linear-gradient(
    to bottom,
    $primary-blue-lighter,
    $primary-blue
  );
}
